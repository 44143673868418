<template>
  <div>
    <sidenav
      :is-sidebar-active.sync="isSidebarActive"
      :item-edit.sync="itemEdit"
      :is-add.sync="isAdd"
      @refetch-data="refetchData"
      :center-options="centerOptions"
      :sector-options="sectorOptions"
      :type-options="cameraTypeOptions"
      :unit-options="unitOptions"
      :reseller-options="resellerOptions"
    />
    <toastification-loading-content ref="loadingToast" />

    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />

    <!-- Filters -->
    <filters
      :dvr-filter.sync="dvrFilter"
      :dvr-options="dvrOptions"
      @update-camera-data="updateCameras"
      :button-update="buttonUpdate"
      :center-filter.sync="centerFilter"
      :center-options="centerOptions"
      :sector-filter.sync="sectorFilter"
      :sector-options="sectorOptions"
      :tenant-filter.sync="tenantFilter"
      :tenant-options="tenantOptions"
      :online-filter.sync="onlineFilter"
      :rtsp-available-filter.sync="rtspAvailableFilter"
    />
    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search')"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(dvr)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <!-- Column: User -->
        <!-- <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <div :style="`background: ${data.item.device.online ? 'green': 'red'};
                        width: 1.5rem;
                        height: 1.5rem;
                        border-radius: 1rem;
                        text-align: center;`">
              </div>
            </template>
            <small>{{ data.item.name }}</small>
          </b-media>
        </template> -->

        <!-- Column: User -->
        <template #cell(status)="data">
          <div style="position: relative;
                        width: 120px;">
            <img class="" :src="`${snapshotUrl}${data.item.device.id}?access_token=${token}`"
              style="width: 120px; border-radius: 16px;"
              @click="openImageModal(`${snapshotUrl}${data.item.device.id}?access_token=${token}`)"
              v-b-modal.image_modal/>
            <div style="z-index: 1;
              position: absolute;
              width: -webkit-fill-available;
              color: white;
              background: rgba(0,0,0,.5);
              padding: 5px;
              top: 0;
              right: 0;
              left: 0;
              border-top-left-radius: 16px;
              border-top-right-radius: 16px;
            ">
              <b-media vertical-align="center">
                <template #aside>
                  <div :style="`background: ${data.item.online ? 'green': 'red'};
                            width: 1.5rem;
                            height: 1.5rem;
                            border-radius: 1rem;
                            text-align: center;`">
                  </div>
                </template>
              </b-media>
            </div>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
            <b-button
                :id="`actions-${data.item.id}`"
                tabindex="0"
                variant="white"
                class="px-1 btn-y"
            >
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
            </b-button>
            <b-popover
                :target="`actions-${data.item.id}`"
                triggers="focus"
                placement="bottom"
                custom-class="popover-p-0"
                variant="white"
            >
                <b-list-group class="m-0">

                    <b-list-group-item
                        v-if="$can('update', 'DahuaDvr')"
                        class="border-0"
                        button
                        @click="openEdit(data.item)"
                    >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50 text-capitalize">
                            {{$t("actions.edit")}}
                        </span>
                    </b-list-group-item>

                    <!-- <b-dropdown-item
                        v-if="$can('delete', 'DahuaDvr')"
                        class="border-0"
                        button
                        @click="confirmDelete(data.item.id)"
                    >
                        <feather-icon icon="TrashIcon"/>
                        <span class="align-middle ml-50 text-capitalize">
                            {{ $t("actions.delete") }}
                        </span>
                    </b-dropdown-item> -->
                </b-list-group>
            </b-popover>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <image-modal />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ref} from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import Filters from "./Filters.vue";
import {useToast} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import ToastificationLoadingContent from "@core/components/toastification/ToastificationLoadingContent.vue";
import store from "@/store";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import Pagination from "@/components/Pagination.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ImageModal from "../../components/GridView/widgets/ImageModal.vue";

export default {
  components: {
    Sidenav,
    Breadcrumb,
    Filters,
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    vSelect,
    ToastificationLoadingContent,
    ImageModal,
  },

  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    const isSidebarActive = ref(false);
    const itemEdit = ref({});
    const isAdd = ref(false);
    const loadingToast = ref(null)
    const buttonUpdate = ref(false);

    const snapshotUrl = `${process.env.VUE_APP_BASE_URL}/v1/services/snapshot/`

    const openEdit = (item) => {
      isSidebarActive.value = true;
      itemEdit.value = item;
      isAdd.value = false;
    };
    const { t } = useI18nUtils();

    const toast = useToast();

    const trans = {
      'messages.delete.title' : t('messages.delete.title'),
      'messages.delete.body' : t('messages.delete.body'),
      'actions.delete' : t('actions.delete'),
      'actions.cancel' : t('actions.cancel'),
      'dahua_camera.dvr_validation' : t('dahua_camera.dvr_validation'),
    }

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      dvrOptions,
      dvrFilter,
      centerOptions,
      centerFilter,
      sectorOptions,
      sectorFilter,
      tenantOptions,
      tenantFilter,
      resellerOptions,
      resellerFilter,
      cameraTypeOptions,
      unitOptions,
      onlineFilter,
      rtspAvailableFilter,
    } = useList();

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: trans["messages.delete.title"],
        text: trans["messages.delete.body"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: trans["actions.delete"],
        cancelButtonText: trans["actions.cancel"],
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner
            .dispatch("dahua_camera/delete", id)
            .then((response) => {
              if (response.success) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
            });
        }
      });
    };

    const updateCameras = () => {
      if (!dvrFilter.value || dvrFilter.value.length === 0) {
        return toast({
          component: ToastificationContent,
          props: {
            title: trans["dahua_camera.dvr_validation"],
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }

      loadingToast.value.show("Actualizando", `Actualizando cámaras ...`, "secondary");
      buttonUpdate.value = true;

      store
        .dispatch("dahua_camera/discover", {
          dvrs: dvrFilter.value,
        })
        .then((response) => {
          if (response.success) {
            loadingToast.value.close();
            buttonUpdate.value = false;
            toast({
              component: ToastificationContent,
              props: {
                title: "Actualizado Correctamente",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            refetchData();
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            buttonUpdate.value = false;
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.vass.dahua.dahua_cameras");

    const openImageModal = async (imageUrl) => {
      await store.dispatch("image_modal/imageUrl", imageUrl);
    };

    return {
      addItemFilter,
      nameNav,
      itemFilter,
      addItemFilterSearch,

      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      confirmDelete,
      update,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      dvrOptions,
      dvrFilter,
      loadingToast,
      updateCameras,
      buttonUpdate,
      centerOptions,
      centerFilter,
      sectorOptions,
      sectorFilter,
      tenantOptions,
      tenantFilter,
      resellerOptions,
      resellerFilter,
      cameraTypeOptions,
      unitOptions,
      onlineFilter,
      rtspAvailableFilter,

      snapshotUrl,
      openImageModal,
    };
  },
};
</script>
