import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('dahua_dvr/getAll')
  store.dispatch('center/getAll')
  store.dispatch('sector/getAll')
  store.dispatch('tenant/getAll')
  store.dispatch('camera_type/getAll')
  store.dispatch('unit/getAll', { with: 'router:id,id_tenant' })
  store.dispatch('omniview_reseller/getAll')

  const dvrOptions = computed(() => store.getters['dahua_dvr/getSelectedItems'])
  const dvrFilter = ref(null)
  const centerOptions = computed(() => store.getters['center/getSelectedItems'])
  const centerFilter = ref(null)
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)
  const sectorOptions = computed(() => store.getters['sector/getSelectedItems'])
  const sectorFilter = ref(null)
  const cameraTypeOptions = computed(() => store.getters['camera_type/getSelectedItems'])
  const unitOptions = computed(() => store.getters['unit/getSelectedItems'])
  const onlineFilter = ref(null)
  const rtspAvailableFilter = ref(null)
  const resellerOptions = computed(() => store.getters['omniview_reseller/getSelectedItems'])

  if (router.currentRoute.params.dvr)
    dvrFilter.value = parseInt(router.currentRoute.dvr)

  const tableColumns = [
    { label: 'camera.status', key: 'status', sortable: true },
    { label: 'dahua_camera.name', key: 'name', sortable: true },
    { label: 'dahua_camera.channel', key: 'channel', sortable: true },
    { label: 'dahua_dvr.title', key: 'dahua_dvr.name', sortable: true },
    {
      label: 'tenants.title', key: 'tenants', sortable: true,
      formatter: (value, key, item) => {
        if (item.units) return [...new Set(item.units.map(item => { return item.router?.tenant?.name }))].join(', ')
        return ""
      }
    },
    {
      label: 'unit.title', key: 'units', sortable: true,
      formatter: (value, key, item) => {
        if (item.units) return [...new Set(value.map(item => { return item.description }))].join(', ')
        return ""
      }
    },
    { label: 'centers.title', key: 'center.name', sortable: true },
    { label: 'sectors.title', key: 'sector.name', sortable: true },
    { label: 'camera_proxy.id', key: 'device.id', sortable: true },
    { label: 'dahua_camera.type', key: 'type', sortable: true },
    { label: 'dahua_camera.ip', key: 'ip', sortable: true },
    { label: 'dahua_camera.class', key: 'class', sortable: true },
    { label: 'dahua_camera.encryption', key: 'encryption', sortable: true },
    { label: 'dahua_camera.poe', key: 'poe', sortable: true },
    { label: 'dahua_camera.poe_port', key: 'poe_port', sortable: true },
    { label: 'dahua_camera.http_port', key: 'http_port', sortable: true },
    { label: 'dahua_camera.https_port', key: 'https_port', sortable: true },
    { label: 'dahua_camera.port', key: 'port', sortable: true },
    { label: 'dahua_camera.port_type', key: 'port_type', sortable: true },
    { label: 'dahua_camera.rtsp_port', key: 'rtsp_port', sortable: true },
    { label: 'dahua_camera.serial_number', key: 'serial_number', sortable: true },
    { label: 'dahua_camera.user', key: 'user', sortable: true },
    {
      label: 'camera.type', key: 'camera_type.type', sortable: true,
    },
    {
      label: 'device_tag.title', key: 'device_tag.name', sortable: true,
    },
    { label: 'camera.previous_time_of_prealarma', key: 'delayed_record_minutes', sortable: true },
    { label: 'camera.later_time_of_prealarma', key: 'forward_record_minutes', sortable: true },
    { label: 'camera_proxy.rtsp_available', key: 'device.rtsp_available', sortable: true },
    { label: 'generic_devices.reseller', key: 'reseller' },
    { label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned' },
    { label: 'date.updated_at', key: 'updated_at', sortable: true, formatter: 'formatDateAssigned' },
  ]
  if (ability.can('update', 'DahuaCamera') == true || ability.can('delete', 'DahuaCamera') == true) {
    tableColumns.splice(0, 0, { label: 'actions.name', key: 'actions' })
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('dahua_camera/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      dvr: dvrFilter.value,
      center: centerFilter.value,
      sector: sectorFilter.value,
      tenant: tenantFilter.value,
      online: onlineFilter.value,
      rtsp_available: rtspAvailableFilter.value,
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([centerFilter, tenantFilter, sectorFilter, onlineFilter, rtspAvailableFilter, dvrFilter, currentPage, perPage, ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,

    dvrOptions,
    dvrFilter,
    centerOptions,
    centerFilter,
    sectorOptions,
    sectorFilter,
    tenantOptions,
    tenantFilter,
    cameraTypeOptions,
    unitOptions,
    onlineFilter,
    rtspAvailableFilter,
    resellerOptions,

    refetchData,
  }
}
