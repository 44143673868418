<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50 text-capitalize">
      <h5>{{$t('filter')}}</h5>
      <b-button
        variant="secondary"
        class="float-right text-capitalize"
        @click="$emit('update-camera-data')"
        :disabled="buttonUpdate"
      >
        {{$t('actions.update') + ' ' + $tc('dahua_camera.title', 1)}}
      </b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="2" md="2" class="mb-md-0 mb-2">
          <label class="text-capitalize">{{ $tc('dahua_dvr.title', 2) }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="dvrFilter"
            :options="dvrOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:dvrFilter', val)"
          />
        </b-col>
        <b-col
          cols="2"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $tc('tenants.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="tenantFilter"
            :options="tenantOptions"
            class="w-100"
            :reduce="val => val.value"
            multiple
            @input="(val) => $emit('update:tenantFilter', val)"
          />
        </b-col>
        <b-col
          cols="2"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{$tc('centers.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="centerFilter"
            :options="centerOptions"
            class="w-100"
            :reduce="val => val.value"
            multiple
            @input="(val) => $emit('update:centerFilter', val)"
          />
        </b-col>
        <b-col
          cols="2"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $tc('sectors.title', 2) }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="sectorFilter"
            :options="sectorOptions"
            class="w-100"
            :reduce="val => val.value"
            multiple
            @input="(val) => $emit('update:sectorFilter', val)"
          />
        </b-col>
        <b-col
          cols="2"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $t('camera.online') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="onlineFilter"
            class="w-100"
            :reduce="val => val.value"
            :options="[{label: 'online', value: true}, {label: 'offline', value: false}]"
            @input="(val) => $emit('update:onlineFilter', val)"
          />
        </b-col>
        <b-col
          cols="2"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $t('camera_proxy.rtsp_available') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="rtspAvailableFilter"
            class="w-100"
            :reduce="val => val.value"
            :options="[{label: 'enabled', value: true}, {label: 'disabled', value: false}]"
            @input="(val) => $emit('update:rtspAvailableFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    dvrOptions: {
      type: Array,
      default: null,
    },
    dvrFilter: {
      type: Array,
      default: null,
    },
    buttonUpdate: {
      type: Boolean,
      default: false,
    },
    centerOptions: {
      type: Array,
      default: null,
    },
    centerFilter: {
      type: Array,
      default: null,
    },
    sectorOptions: {
      type: Array,
      default: null,
    },
    sectorFilter: {
      type: Array,
      default: null,
    },
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    onlineFilter: {
      type: Boolean,
      default: false,
    },
    rtspAvailableFilter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>