<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ (isAdd ? $t("actions.add") : $t("actions.edit")) + ' ' + $tc("dahua_camera.title", 1) }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('dahua_camera.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Unit -->
          <validation-provider #default="validationContext" name="Unit">
            <b-form-group
              class="text-capitalize"
              :label="$tc('unit.title', 2)"
              label-for="id_unit"
              :state="getValidationState(validationContext)"
            >
              <select-paginate
                v-model="itemData.id_unit"
                :items="unitOptions"
                input-id="id_unit"
                :multiple="true"
                @change="updateDeviceTags"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Center -->
          <validation-provider #default="validationContext" name="Center">
            <b-form-group
              class="text-capitalize"
              :label="$tc('centers.title', 1)"
              label-for="id_center"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_center"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="centerOptions"
                :reduce="(val) => val.value"
                input-id="id_center"
                @input="changeCenter"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Sector -->
          <validation-provider #default="validationContext" name="Sector">
            <b-form-group
              label="Sector"
              label-for="id_sector"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_sector"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sectorOptions"
                :reduce="(val) => val.value"
                input-id="id_sector"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Omniview reseller -->
          <validation-provider
            #default="validationContext"
            :name="$t('generic_devices.reseller')"
            rules="required"
          >
            <b-form-group
              :label="$t('generic_devices.reseller')"
              label-for="reseller"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_omniview_reseller"
                :options="resellerOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="reseller"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type -->
          <validation-provider #default="validationContext" name="Type" rules="required">
            <b-form-group
              class="text-capitalize"
              :label="$t('camera.type')"
              label-for="id_type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- delayed_record_minutes -->
          <validation-provider
            #default="validationContext"
            name="delayed_record_minutes"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('camera.previous_time_of_prealarma')"
              label-for="delayed_record_minutes"
            >
              <b-form-timepicker
                id="delayed_record_minutes"
                v-model="itemData.delayed_record_minutes"
                :state="getValidationState(validationContext)"
                trim
                show-seconds
                placeholder=""
                reset-button
              />
            </b-form-group>
          </validation-provider>

          <!-- forward_record_minutes -->
          <validation-provider
            #default="validationContext"
            name="forward_record_minutes"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('camera.later_time_of_prealarma')"
              label-for="forward_record_minutes"
            >
              <b-form-timepicker
                id="forward_record_minutes"
                v-model="itemData.forward_record_minutes"
                :state="getValidationState(validationContext)"
                trim
                show-seconds
                placeholder=""
                reset-button
              />
            </b-form-group>
          </validation-provider>

          <!-- Tags -->
          <validation-provider #default="validationContext" name="Tag">
            <b-form-group
              class="text-capitalize"
              label="Tag"
              label-for="id_device_tag"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_device_tag"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deviceTagsOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_device_tag"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider name="rtsp_available" rules="required" vid="rtsp_available">
            <b-form-checkbox
              id="rtsp_available"
              v-model="itemData.rtsp_available"
              name="rtsp_available"
              value="1"
              class="my-1 text-capitalize"
              unchecked-value="0"
              switch
            >
              {{ $t("camera_proxy.rtsp_available") }}
            </b-form-checkbox>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") :$t("actions.edit") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
             {{$t('actions.cancel')}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { computed, ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import SelectPaginate from "../../components/SelectPaginate.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BButton,
    vSelect,
    BFormCheckbox,
    SelectPaginate,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
    centerOptions: {
      type: Array,
      required: true,
    },
    sectorOptions: {
      type: Array,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    unitOptions: {
      type: Array,
      required: true,
    },
    resellerOptions: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const blankData = {
      name: null,
      id_center: null,
      id_sector: null,
      id_unit: null,
      id_type: null,
      delayed_record_minutes: "",
      forward_record_minutes: "",
      id_device_tag: null,
      rtsp_available: false,
      id_omniview_reseller: null,
    };

    const toast = useToast();

    const deviceTagsOptions = computed(() => {
      return store.getters["tenant/getDeviceTags"].map((item) => ({
        label: item.name,
        value: item.id,
      }));
    });

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
      store.dispatch("sector/getAll");
    };

    const onSubmit = () => {
      let dispatch;

      const req = {
        id: itemData.value.id,
        params: {
          id_unit:
            itemData.value.id_unit.length > 0
              ? itemData.value.id_unit.map((unit) => unit.value)
              : null,
          id_center: itemData.value.id_center || null,
          id_sector: itemData.value.id_sector || null,
          id_type: itemData.value.id_type,
          delayed_record_minutes: itemData.value.delayed_record_minutes,
          forward_record_minutes: itemData.value.forward_record_minutes,
          id_device_tag: itemData.value.id_device_tag,
          rtsp_available: itemData.value.rtsp_available,
          name: itemData.value.name,
          id_omniview_reseller: itemData.value.id_omniview_reseller
        },
      };

      if (props.isAdd) {
        dispatch = store.dispatch("dahua_camera/add", itemData.value);
      } else {
        dispatch = store.dispatch("dahua_camera/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const changeCenter = () => {
      store
        .dispatch("sector/getAll", {
          id_center: [itemData.value.id_center],
        })
        .then(() => {
          const sector = props.sectorOptions.filter(
            (item) => item.value == itemData.value.id_sector
          )

          if (sector.length == 0) itemData.value.id_sector = ""
        })
    }

    const updateDeviceTags = () => {
      if (itemData.value.id_unit.length > 0) {
        const idTenants = itemData.value.id_unit.map((unit) => unit.id_tenant);
        store.dispatch("tenant/getDeviceTagsByTenantIds", { id_tenants: idTenants });
      }
    };

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;

        itemData.value.id_unit = props.itemEdit.units.map((value) => ({
          value: value.id,
          label: value.description,
          id_tenant: value.router.id_tenant,
        }));

        itemData.value.id_center = props.itemEdit.center?.id;
        itemData.value.id_sector = props.itemEdit.sector?.id;
        itemData.value.id_type = props.itemEdit.camera_type?.id;
        updateDeviceTags();
        changeCenter();
      }
    };

    return {
      itemData,
      onSubmit,
      changeCenter,
      updateDeviceTags,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      deviceTagsOptions,
    };
  },
};
</script>

